<template>
  <div id="activityCases">
    <el-button type="primary" class="team-server smallBtn" @click="$router.push('/activityAdd')"  size="small">添加活动</el-button>
    <!-- 表格 -->
    <el-table :data=" activeData" style="width: 100%">
      <el-table-column align="center" prop="name" label="活动名称"></el-table-column>
      <el-table-column align="center" prop="source" label="活动来源"></el-table-column>
      <el-table-column align="center" prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button @click="editActive(scope.row)" type="primary" size="mini" class="editTab">编辑</el-button>
          <el-button @click="DelActive(scope.row)" type="danger" size="mini" class="delTab">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Page
      @currentPageChange="currentPageChange"
      :pageSize="pageData.per_page"
      :page="pageData.current_page"
      :total="pageData.total"
      style="text-align:center;margin-top:10px"
    ></Page>
  </div>
</template>
<script>
import Page from "@/components/PageComponents";
import { success } from "@/utils/notification";

export default {
  name: 'activityCases',
  data() {
    return {
      id: Number(sessionStorage.organizationId),
      activeData: [],
      page: 1,
      pageData: {
        //分页数据
        per_page: 0,
        current_page: 0,
        total: 0
      }
    }
  },
  components: {
    Page
  },
  methods: {
    //分页切换
    currentPageChange(p) {
      this.page = p;
      this.getAcitveList();
    },
    //编辑活动
    editActive(row) {
      this.$router.push({path:'/activityAdd',query:{id:row.id}})
    },
    //删除活动
    DelActive(row) {
      this.$confirm('此操作将删除该活动案例, 是否继续?', '提示', {
        customClass: 'activityDialog',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.URL.adminUrl.mediaOrActivityList +'/'+ row.id).then(res => {
          this.getAcitveList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
      });
    },
    //获取活动列表
    getAcitveList() {
      this.$http.get(this.URL.adminUrl.mediaOrActivityList, {
        params: {
          page: this.page,
          organ_id: this.id,
          type: 'activity'
        }
      }).then(res => {
        this.activeData = res.data.data.data;
        this.pageData = res.data.data.meta;
      });
    }
  },
  created() {
    this.getAcitveList();
  }
}
</script>
<style lang="less" scoped>
  .team-server {
    float: right;
    margin: 10px 0 30px;
  }
</style>
<style lang="less">
  .activityDialog{
    border: 0px!important;
    .el-message-box__btns{
      margin-bottom: 0px;
    }
		.el-message-box__close{
			color: #ffffff;
		}
  }
</style>
